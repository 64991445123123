.slick-dot-style {
  .slick-dots {
    position: absolute;
    bottom: -15px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    li {
      position: relative;
      display: inline-block;
      width: 16px;
      height: 14px;
      margin: 0 3px;
      padding: 0;
      cursor: pointer;
      button {
        font-size: 0;
        line-height: 0;
        display: block;
        width: 14px;
        height: 14px;
        padding: 0px !important;
        cursor: pointer;
        color: transparent;
        border: 0;
        outline: none;
        background: transparent;
        &:before {
          font-size: 14px;
          line-height: 10px;
          position: absolute;
          top: 0;
          left: 0;
          width: 14px;
          height: 14px;
          content: '○';
          text-align: center;
          opacity: 0.5;
          color: black;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
        &:--enter {
          &:before {
            opacity: 1;
          }
        }
      }
      &.slick-active {
        button {
          &:before {
            opacity: 0.8;
            content: '●';
            color: black;
          }
        }
      }
    }
  }
}

@media (--small-viewport) {
  .slick-dot-style {
    .slick-dots {
      position: relative;
      bottom: 0;
    }
  }
}

/* slick arrow */
.slick-arrow-inside {
  position: relative;
  .custom-slick-arrows {
    position: absolute;
    width: 100%;
    top: 50%;
    right: 0;
    left: 0;
    z-index: 1;
    transform: translateY(-50%);
  }
}