.content-block {
  &--index {
    padding: 20px 0;
    .section-title__title {
      &:after {
        width: 250px;
      }
    }
    .pattern-bg {
      width: 100%;
      max-width: 670px;
      height: 100%;
    }
    &__ltr {
      .pattern-bg {
        right: 0;
        top: 0;
      }
      &__text {
        position: relative;
        font-size: calc(var(--fontSize) * 1.25);
        .custom-btn--more {
          float: right;
        }
      }
    }
    &__rtl {
      .pattern-bg {
        left: 0;
        top: 0;
      }
      &__text {
        position: relative;
        font-size: calc(var(--fontSize) * 1.25);
      }
    }
  }
  &__content {
    margin-bottom: 0;
  }
}

@media (--large-viewport) {
  .content-block {
    &--index {
      padding: 60px 0;
    }
  }
}

@media (--medium-viewport) {
  .content-block {
    &--index {
      padding-bottom: 0;
      &__ltr {
        &__image {
          padding-left: 0;
          padding-right: 0;
          text-align: center;
          margin-top: 100px;
          margin-bottom: 0;
        }
      }
      &__rtl {
        &__image {
          padding-left: 0;
          padding-right: 0;
          text-align: center;
          margin-bottom: 0;
        }
      }
    }
  }
}

.news-block--index {
  background-color: #f4f5f9;
  background-repeat: no-repeat;
  background-position: top right;
  padding: 60px 0;
  &__outer {
    max-width: 100%;
    padding: 0 10px;
  }
  &__inner {
    position: relative;
    margin: 50px 0;
  }
  .news-slider {
    padding-bottom: 50px;
  }
  &__customArrow {
    position: absolute;
    left: 10px;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        width: 43px;
        height: 43px;
        line-height: 40px;
        margin-right: 10px;
        color: var(--brandRed);
        border: 3px solid var(--brandRed);
        display: inline-block !important;
        text-align: center;
        font-size: calc(var(--fontSize) * 1.125);
        cursor: pointer;
        a {
          color: var(--brandRed);
        }
        &:--enter {
          color: #fff;
          background: var(--brandRed);
          a {
            color: #fff;
          }
        }
      }
    }
  }
  .custom-btn--more {
    position: absolute;
    right: 10px;
  }
}

@media (--large-viewport) {
  .news-block--index {
    &__outer {
      max-width: 80%;
      margin: 0 auto;
    }
  }
}

@media (--medium-viewport) {
  .news-block--index {
    padding: 30px 0 60px;
  }
}

.project-block {
  position: relative;
  &--index {
    padding: 60px 0;
    &__outer {
      max-width: 100%;
      padding: 0 10px;
    }
    &__inner {
      position: relative;
      display: block;
      margin: 50px 0;
      .project-slider {
        padding-bottom: 50px;
      }
    }
    &__customArrow {
      position: absolute;
      left: 10px;
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
          width: 43px;
          height: 43px;
          line-height: 40px;
          margin-right: 10px;
          color: var(--brandRed);
          border: 3px solid var(--brandRed);
          display: inline-block !important;
          text-align: center;
          font-size: calc(var(--fontSize) * 1.125);
          cursor: pointer;
          a {
            color: var(--brandRed);
          }
          &:--enter {
            color: #fff;
            background: var(--brandRed);
            a {
              color: #fff;
            }
          }
        }
      }
    }
  }
}
@media (--xxlarge-viewport) {
  .project-block--index {
    &__outer {
      max-width: 80%;
    }
  }
}

@media (--large-viewport) {
  .project-block--index {
    &__outer {
      max-width: 90%;
      margin: 0 auto;
    }
    &__inner {
      margin: 70px 0;
    }
  }
}

@media (--medium-viewport) {
  .project-block--index {
    padding: 30px 0 60px;
  }
}
