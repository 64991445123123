.backtop {
  position: fixed;
  right: 25px;
  bottom: 90px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  background: var(--brandRed);
  z-index: 99;
  text-align: center;
  cursor: pointer;
  transition: all .3s ease-out;
  opacity: 0;
  transform: translateY(0px);
  img {
    max-width: 14px;
  }
  i {
    font-size: calc(var(--fontSize) * 1.25);
    color: #fff;
  }
  &.show {
    opacity: .6;
  }
  &:--enter {
    background: color(var(--brandRed) shade(15%));
    transform: translateY(-5px);
    opacity: 1;
  }
}

@media (--xxsmall-viewport) {
  .backtop {
    right: 10px;
  }
}