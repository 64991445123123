.section-title {
  margin-top: 30px;
  margin-bottom: 30px;
  overflow-x: hidden;
  &__title {
    position: relative;
    font-size: calc(var(--fontSize) * 2);
    color: var(--textColor);
    &:after {
      position: absolute;
      content: '';
      top: 50%;
      height: 3px;
      width: 250px;
      background-color: var(--brandRed);
      -webkit-transform: translateY(-50%) scale(1, 1);
      transform: translateY(-50%) scale(1, 1);
      margin-left: 20px;
    }
  }
}

.pattern-bg {
  position: absolute;
  content: '';
  background-repeat: repeat;
}

.social-link {
  margin: 0;
  padding: 0;
  list-style: none;
  &--header {
    display: flex;
    align-items: center;
    height: 100%;
  }
  &__item {
    display: inline-block;
    vertical-align: middle;
    &__content {
      display: inline-block;
      color: #000;
      font-size: calc(var(--fontSize) * 1.125);
      padding: 10px 13px;
      &.library {
        font-size: calc(var(--fontSize) * 1);
      }
      &.library:--enter {
        color: var(--linkColor);
      }
      &.facebook:--enter {
        color: var(--fbColor);
      }
      &.line:--enter {
        color: var(--lineColor);
      }
      &.instagram:--enter {
        color: var(--igColor);
      }
    }
  }
}

@media (--medium-viewport) {
  .social-link {
    &__item {
      display: flex;
      flex: 1 1 auto;
      text-align: center;
      justify-content: center;
    }
  }
  .social-link--header {
    justify-content: flex-end;
    &--item {
      a {
        color: #fff;
        padding: 7px 13px;
        width: 100%;
      }
    }
  }
}

.pagination_link {
  float: right;
}
.pagination {
  > li {
    margin: 0 5px;
    display: inline-block;
    > a,
    > span {
      color: #000;
      font-size: calc(var(--fontSize) * 1);
      background: #fff;
      border: 2px solid #ccc;
      border-radius: 0 !important;
      transition: all 0.3s;
      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        background-color: #222;
        width: 100%;
        height: 100%;
        transform: scale(0);
        transition: all 0.3s;
        z-index: -1;
      }
    }
    &:--enter {
      > a,
      > span {
        color: #fff;
        border: 2px solid #222;
        &:after {
          opacity: 1;
          transform: scale(1);
        }
      }
    }
  }
}

.content-block--pageNav {
  margin-top: 60px;
  margin-bottom: 30px;
  &--groupLeft {
    float: left;
  }
  &--groupRight {
    float: right;
  }
  &--link {
    display: inline-block;
    margin-right: 10px;
  }
}

@media (--small-viewport) {
  .content-block--pageNav {
    &--groupLeft,
    &--groupRight {
      float: none;
      display: block;
      margin-bottom: 30px;
    }
  }
}

@media (--xsmall-viewport) {
  .content-block--pageNav {
    &--groupLeft,
    &--groupRight {
      margin-bottom: 0;
    }
  }
  .content-block--pageNav--link {
    display: block;
    width: 100%;
    margin-right: 0;
    margin-bottom: 15px;
    .custom-btn--border {
      display: block;
    }
  }
}

.table {
  border: 1px solid #ddd;
  thead {
    background: #ddd;
  }
}

.show-more {
  display: block;
  width: 100%;
}

.responsive-map {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
}

@media (--large-viewport) {
  .responsive-map {
    padding-bottom: 46.25%;
  }
}

.slick-arrow a {
  display: inline-block;
  width: 100%;
  height: 100%;
}

.news-block {
  position: relative;
  &__item {
    position: relative;
    padding: 0 10px;
    &__inner {
      position: relative;
      overflow: hidden;
      padding-bottom: 55%;
      transition: all 0.3s ease;
      &--image {
        position: absolute;
        content: '';
        background-size: 100%;
        background-repeat: no-repeat;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
      }
      .overlay-box {
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        top: 0;
        z-index: 2;
        opacity: 0;
        display: block;
        transition: all 0.5s ease;
        &:before {
          position: absolute;
          content: '';
          right: 0px;
          top: 0px;
          width: 100%;
          height: 0%;
          display: block;
          opacity: 0;
          text-align: center;
          transform: scaleY(0);
          transform-origin: top;
          transition: all 0.5s;
          background-color: color(var(--brandYellow) a(70%));
        }
        &--inner {
          position: absolute;
          left: 0;
          top: 30px;
          width: 100%;
          height: 100%;
          display: table;
          z-index: 1;
          padding: 10px 10px;
          vertical-align: middle;
          opacity: 0;
          transition: all 0.5s ease;
        }
        &--content {
          position: relative;
          text-align: center;
          display: table-cell;
          vertical-align: middle;
          i {
            font-size: calc(var(--fontSize) * 1.125);
            color: var(--brandYellow);
            background: #fff;
            padding: 13px;
            border-radius: 5px;
          }
        }
      }
    }
    &__bottom {
      padding: 20px;
    }
    .news-date {
      font-size: calc(var(--fontSize) * 0.95);
      color: var(--brandRed);
    }
    .news-title {
      font-weight: 600;
      color: #000;
      font-size: calc(var(--fontSize) * 1.25);
      margin: 5px 0;
    }
    &__link {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 2;
    }
    &:--enter & {
      &__inner {
        .overlay-box {
          opacity: 1;
          &:before {
            opacity: 1;
            transform: scaleY(1);
            height: 100%;
          }
          &--inner {
            opacity: 1;
            top: 0;
            transition-delay: 300ms;
          }
          &--content {
            i {
              color: #fff;
              background: #222;
            }
          }
        }
      }
    }
  }
}

.project-slider__item {
  position: relative;
  cursor: move;
  padding: 0 10px;
  &__inner {
    position: relative;
    transition: all 0.3s ease;
    background: #f9f9f9;
    display: table;
    &--image {
      position: relative;
      display: table-cell;
      width: 200px;
      height: 200px;
      overflow: hidden;
      img {
        width: 100%;
        transition: all 0.6s ease;
      }
      .overlay-box {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        &:after {
          position: absolute;
          content: '';
          left: 0;
          top: -100%;
          width: 100%;
          height: 100%;
          opacity: 0;
          transition: all 0.7s ease;
          background: color(#000 a(70%));
        }
        &--links {
          position: absolute;
          left: 0;
          top: 50%;
          margin-top: -22px;
          width: 100%;
          text-align: center;
          opacity: 0;
          transform: translateY(-70px);
          transition: all 0.5s ease;
          z-index: 1;
          .link {
            position: relative;
            display: inline-block;
            width: 44px;
            height: 44px;
            line-height: 44px;
            font-size: calc(var(--fontSize) * 1);
            background: #fff;
            color: var(--brandYellow);
            margin: 0px 3px;
            border-radius: 3px;
            transition: all 0.3s ease;
            &:--enter {
              color: #fff;
              background: var(--brandYellow);
            }
          }
        }
      }
    }
    &--text {
      position: relative;
      display: table-cell;
      vertical-align: top;
      width: calc(100% - 200px);
      padding: 25px;
      h3 {
        font-size: calc(var(--fontSize) * 1.75);
        line-height: normal;
        color: var(--brandRed);
        font-weight: 500;
        margin-top: 10px;
        margin-bottom: 20px;
        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .intro {
        font-size: calc(var(--fontSize) * 1.125);
      }
      .custom-btn--more {
        position: absolute;
        right: 25px;
        bottom: 25px;
      }
    }
    &:--enter & {
      &--image {
        img {
          transform: scale(1.2, 1.3);
        }
        .overlay-box {
          &:after {
            opacity: 1;
            top: 0%;
          }
          &--links {
            opacity: 1;
            transform: translateY(0px);
          }
        }
      }
    }
  }
}
@media (--xxlarge-viewport) {
  .project-slider__item__inner {
    &--image {
      width: 300px;
      height: 300px;
    }
    &--text {
      width: calc(100% - 300px);
    }
  }
}
@media (--xlarge-viewport) {
  .project-slider__item__inner {
    &--image {
      width: 40%;
      height: auto;
    }
    &--text {
      width: calc(100% - 40%);
    }
  }
}
@media (--medium-viewport) {
  .project-slider__item__inner {
    display: block;
    &--image {
      width: 100%;
      height: auto;
      display: block;
    }
    &--text {
      width: 100%;
      display: block;
      .custom-btn--more {
        position: relative;
        right: auto;
        bottom: auto;
        margin-top: 20px;
      }
    }
  }
}
