html {
  width: 100%;
  touch-action: manipulation;
}

body {
  color: var(--textColor);
  font-size: calc(var(--fontSize) * 1);
  font-family: 'Muli', '微軟正黑體', 'Microsoft JhengHei', Helvetica, sans-serif;
  margin: auto;
  line-height: 1.4;
  background: var(--white);
  -webkit-overflow-scrolling: auto;
}

h1.head {
  margin: 0;
}

a {
  cursor: pointer;
  color: var(--brandYellow);
  transition: all 0.2s;
  &:--enter {
    outline: 0;
    color: var(--brandRed);
    outline-offset: 0px;
    text-decoration: none;
  }
}

img {
  display: inline-block;
  max-width: 100%;
  height: auto;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}
