header {
  position: relative;
  width: 100%;
  animation-duration: 200ms;
  z-index: 99;
  .main-header {
    padding: 1.5vh 0;
    /* max-width: 80%; */
    width: calc(100% - 80px);
    margin: 0;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    .logo-box {
      position: relative;
      transition: all .2s ease;
    }
    .brand {
      position: relative;
      width: 100%;
      max-width: 340px;
      margin: 0 10px;
      img {
        width: 100%;
      }
    }
    .language-box {
      text-align: center;
      font-size: calc(var(--fontSize) * 1);
      ul {
        padding: 0;
        margin: 0;
        list-style: none;
        li {
          float: left;
          a {
            display: inline-block;
            padding: 6px 15px;
            color: #888;
            &:--enter, &.current {
              color: #fff;
              background: var(--brandRed);
            }
          }
        }
      }
    }
  }
  @media (--small-viewport) {
    header .main-header {
      width: calc(100% - 60px);
      justify-content: flex-start;
      margin: 0;
      .brand {
        width: auto;
      }
      .language-box {
        display: none;
      }
    }
  }
  @media (--large-viewport) {
    header .main-header {
      max-width: 80%;
      margin: 0 auto;
      width: 100%;
    }
  }
  @media (--xlarge-viewport) {
    header .main-header {
      padding: 2.5vh 0;
      justify-content: space-between;
    }
  }
}

/* Sticky Menu */
header.fixed {
  position: fixed;
  left: 0;
  top: 0;
  background: #fff;
  /* box-shadow: 0 2px 20px color(#000 a(10%)); */
  border-bottom: 1px solid #f7f7f7;
  transition: all .2s ease;
  animation-name: fadeInDown;
  animation-duration: 200ms;
  animation-timing-function: linear;
  animation-iteration-count: 1;
  .main-header {
    padding: .5vh 0;
  }
}