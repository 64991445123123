/* custom properties */
:root {
  --brandYellow: #f9c158;
  --brandRed: #e4183a;
  --brandGreen: #00978c;

  --white: #fff;
  --black: #000;
  --textColor: #222;
  --navColor: #222;
  --greyColor: #d5d5d5;
  --formBG: #eee;
  --footerColor: #565656;

  --panelColor: #8dc2e0;

  /*social colours*/
  --linkColor: #888;
  --fbColor: #1778f2;
  --twColor: #1da1f2;
  --lineColor: #00c300;
  --ytColor: #cd201f;
  --igColor: #e1306c;
  --emailColor: #3c95da;

  /*base font*/
  --fontSize: 16px;

  --gutter: {
    display: inline-block;
    width: 100%;
    margin: 15px 0;
  }
}

@font-face {
  font-family: 'Muli', sans-serif;
  src: url('../fonts/Muli-Regular.ttf');
  src: url('../fonts/Muli-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Muli', sans-serif;
  src: url('../fonts/Muli-Bold.ttf');
  src: url('../fonts/Muli-Bold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Muli', sans-serif;
  src: url('../fonts/Muli-Black.ttf');
  src: url('../fonts/Muli-Black.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@custom-selector :--enter :hover, :focus, :active, .active;

:--enter {
  transition: all 0.2s;
  text-decoration: none;
}

/* custom selectors */
@custom-selector :--multiLineHidden .multiline-text-overflow;
:--multiLineHidden {
  display: block;
  display: -webkit-box;
  max-width: 100%;
  height: 78px;
  font-size: calc(var(--fontSize) * 1.125);
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@custom-selector :--heading h1, h2, h3, h4, h5, h6;

:--heading {
  font-weight: 700;
}

@custom-selector :--center-box .center-box;

:--center-box {
  display: table;
  margin: 0 auto;
}

@custom-selector :--center-box-inner .center-box-inner;

:--center-box-inner {
  display: table-cell;
  vertical-align: middle;
}
