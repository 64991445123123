#banner {
  position: relative;
}
.main-slider {
  width: 100%;
  opacity: 0;
  visibility: hidden;
  /* transition: all 1.2s ease; */
  &.slick-initialized {
    opacity: 1;
    visibility: visible;
  }
  &__item {
    position: relative;
    height: 40vw;
    backface-visibility: hidden;
    iframe {
      position: relative;
      pointer-events: none;
    }
    figure {
      height: 100%;
    }
    .slide-image {
      position: relative;
      opacity: 0;
      height: 100%;
      background-size: cover;
      background-position: center;
      transition: all 0.3s ease;
      &:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: color(#000 a(40%));
      }
      &.image-pc {
        display: block !important;
      }
      &.image-mobile {
        display: none !important;
      }
      &.show {
        opacity: 1;
      }
    }
    .image-entity {
      width: 100%;
      opacity: 0;
      visibility: hidden;
    }
    .slide-media {
      animation: slideOut 0.4s cubic-bezier(0.4, 0.29, 0.01, 1);
      &-image {
        display: none;
      }
    }
    .slide-caption {
      position: absolute;
      /* bottom: -80px; */
      bottom: 10%;
      right: 10%;
      opacity: 0;
      z-index: 1;
      /* transition: all .3s cubic-bezier(0.32, 0.34, 0, 1.62) .6s; */
      transform: translateY(-5%);
      visibility: hidden;
      -webkit-font-smoothing: subpixel-antialiased;
      [class^='caption-order-'],
      [class*=' caption-order-'] {
        opacity: 0;
        transform: translate(-5vw, 0);
        display: inline-block;
      }
    }
    .custom-btn--red {
      opacity: 0;
      transform: translate(-5vw, 0);
    }
    &.slick-active {
      z-index: 1;
      .slide-caption {
        opacity: 1;
        /* bottom: 10%;
        transform: translateY(-5%); */
        transition: opacity 0.6s;
        visibility: visible;
        /* transition: opacity .4s 0s,visibility 0s 0s; */
        /* transition: all .7s cubic-bezier(0.32, 0.34, 0, 1.62) .6s; */
        [class^='caption-order-'],
        [class*=' caption-order-'] {
          opacity: 1;
          transition: opacity 0.6s ease-out, transform 0.6s ease-out;
          transition-delay: 0s, 0s;
          transform: translate(0, 0);
        }
        .caption-order-1 {
          transition-delay: 0.3s;
        }
        .caption-order-2 {
          transition-delay: 0.4s;
        }
        .caption-order-3 {
          transition-delay: 0.5s;
        }
      }
      .custom-btn--red {
        opacity: 1;
        transition: opacity 0.6s ease-out, transform 0.6s ease-out;
        transition-delay: 0s, 0s;
        transform: translate(0, 0);
        transition-delay: 0.6s;
      }
    }
  }
  .caption {
    color: #fff;
    margin: 0;
    padding: 30px;
    /* background: color(#fff a(70%)); */
    text-shadow: 1px 1px 5px color(#000 a(40%));
    .slide-main-caption {
      font-size: calc(var(--fontSize) * 1.88);
      margin-bottom: 20px;
    }
    span.highlight {
      color: var(--brandRed);
    }
  }
  .loading {
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
  }
  &.slick-slider {
    .slick-dots {
      position: absolute;
      padding: 0;
      text-align: center;
      width: 100%;
      li {
        display: inline-block;
        button {
          color: #eee;
          font-size: calc(var(--fontSize) * 1.125);
          background: none;
          padding: 0 10px !important;
          margin: 0 7px;
          border-radius: 0;
          border: none;
          border-bottom: 4px solid #eee;
          text-indent: -9999px;
        }
        &.slick-active {
          button {
            color: var(--brandRed);
            border-color: var(--brandRed);
            padding: 0 15px !important;
          }
        }
      }
    }
  }
  &__customArrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        width: 60px;
        height: 60px;
        color: #000;
        text-align: center;
        background: #fff;
        position: absolute;
        font-size: calc(var(--fontSize) * 1.125);
        cursor: pointer;
        transition: all 0.3s ease;
        a {
          color: #000;
          padding: 18px 0;
        }
        &:--enter {
          color: #fff;
          background: #222;
          a {
            color: #fff;
          }
        }
      }
    }
    &__prev {
      left: 0;
    }
    &__next {
      right: 0;
    }
  }
}

@media (--xlarge-viewport) {
  .main-slider {
    &__item {
      .caption {
        .slide-main-caption {
          font-size: calc(var(--fontSize) * 2.25);
        }
      }
    }
  }
}

@media (--medium-viewport) {
  .main-slider {
    &__item {
      .caption {
        .slide-main-caption {
          font-size: calc(var(--fontSize) * 1.25);
        }
      }
    }
  }
}
@media (--small-viewport) {
  .main-slider {
    height: 100vw;
    &__item {
      height: 100vw;
      .image-entity {
        width: 100%;
        opacity: 1;
        visibility: visible;
      }
      .slide-image {
        &.image-pc {
          display: none !important;
        }
        &.image-mobile {
          display: block !important;
          height: 100vw;
          background-repeat: no-repeat;
          background-size: cover;
        }
      }
      .slide-media {
        display: none !important;
        &-image {
          display: block !important;
        }
      }
    }
    .caption {
      padding: 15px;
    }
    &.slick-slider {
      .slick-dots {
        li {
          button {
            font-size: calc(var(--fontSize) * 1);
            margin: 0 6px;
          }
        }
      }
    }
    &__customArrow {
      display: none;
    }
  }
}
