.main-content {
  overflow-x: hidden;
}
.content-block {
  &__content {
    position: relative;
  }
  &--text {
    margin-top: 20px;
  }
  &--editArea {
    font-size: calc(var(--fontSize) * 1.125);
  }
  .item__inner {
    &--text {
      padding: 30px;
      box-shadow: 0 0 25px color(#000 a(10%));
      background: #fff;
    }
  }
  &--verticalTabs {
    position: relative;
    margin-left: -15px;
    margin-right: -15px;
    background: var(--formBG);
  }
}
@media (--medium-viewport) {
  .content-block {
    &--text {
      padding: 0;
    }
  }
}

.page-header {
  text-align: center;
  color: #fff;
  margin: 0;
  border: 0;
  padding: 40px 0;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;
  /* &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: color(#000 a(70%));
    z-index: -1;
  } */
  &__title {
    text-align: right;
    font-size: calc(var(--fontSize) * 2.5);
    font-weight: 700;
    margin: 10px 0;
  }
}

.breadcrumb {
  background: none;
  padding: 5px 0;
  li {
    color: #000;
    a {
      color: var(--brandRed);
      font-size: calc(var(--fontSize) * 1.375);
      &:--enter {
        text-decoration: underline;
      }
    }
    + li:before {
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
      content: '\f105';
      font-size: calc(var(--fontSize) * 1);
      color: var(--brandRed);
      padding: 0 15px;
    }
    &.active a {
      color: #000;
      &:--enter {
        color: var(--brandRed);
      }
    }
  }
}

.page-content {
  padding: 50px 0;
  position: relative;
  &__content {
    position: relative;
  }
  .section-title__title {
    font-size: calc(var(--fontSize) * 3);
    &:after {
      width: 100%;
      max-width: 500px;
    }
  }
}
@media (--medium-viewport) {
  .page-content {
    padding: 30px 0;
  }
}
