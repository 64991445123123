@custom-selector :--button button, .btn;
:--button {
  padding: 8px 16px;
  border-radius: 0;
	color: var(--white);
	background: var(--brandYellow);
	transition: all .3s;
	display: inline-block;
	text-decoration: none;
	text-shadow: none;
}
:--button:--enter {
	text-decoration: none;
}

@custom-selector :--custom-button .custom-btn;
:--custom-button {
  padding: 10px 20px;
  border-radius: 0;
	color: var(--white);
	background: var(--brandYellow);
	transition: all .3s;
	z-index: 0;
	display: inline-block;
	position: relative;
	text-decoration: none;
	text-shadow: none;
	&:before, &:after {
		content: '';
    background-color: color(#fff a(20%));
    height: 50%;
    width: 0;
    position: absolute;
    transition: 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
	}
	&:before {
		top: 0;
		left: 0;
		right: auto;
	}
	&:after {
		bottom: 0;
    right: 0;
    left: auto;
	}
	span {
		display: block;
		font-size: calc(var(--fontSize) * 0.875);
		font-weight: 600;
		letter-spacing: calc(var(--fontSize) * 0.0875);
    transform-origin: center left;
    transition: color 0.3s ease;
    position: relative;
    z-index: 1;
	}
}
:--custom-button:--enter {
	color: var(--white);
	background: color(var(--brandYellow) shade(15%));
	text-decoration: none;
	&:before {
		width: 100%;
		right: 0;
		left: auto;
	}
	&:after {
		width: 100%;
    left: 0;
    right: auto;
	}
}

@custom-selector :--submit-button .submit-btn;
:--submit-button {
	border: none;
	margin-top: 20px;
	padding: 12px 50px;
	span {
		padding-right: 0;
		font-size: calc(var(--fontSize) * 1.125);
	}
}

@custom-selector :--custom-button--border .custom-btn--border;
:--custom-button--border {
	color: var(--brandRed);
	padding: 12px 50px;
	border: 3px solid var(--brandRed);
	background: transparent;
	display: inline-block;
	font-size: calc(var(--fontSize) * 1.375);
	img.button-backArrow {
		display: inline-block;
		max-width: 22px;
		margin-right: 5px;
		transition: all 0.3s ease;
	}
	span {
		font-size: calc(var(--fontSize) * 1.375);
		line-height: 1;
	}
}
:--custom-button--border:--enter {
	color: #fff;
	background: var(--brandRed);
	img.button-backArrow {
		transform: translateX(-3px);
	}
}

@media (--small-viewport) {
	:--button {
		padding: 6px 12px;
	}
	:--custom-button {
		padding: 10px 17px;
	}
	:--custom-button--border {
		padding: 7px 20px;
	}
}

@custom-selector :--button-yellow .btn--yellow, .custom-btn--yellow;
:--button-yellow {
	color: #222;
	background: var(--brandYellow);
}
:--button-yellow:--enter {
	color: #000;
	background: color(var(--brandYellow) shade(10%));
}

@custom-selector :--button-red .btn--red, .custom-btn--red;
:--button-red {
	color: #fff;
	background: var(--brandRed);
}
:--button-red:--enter {
	color: #fff;
	background: color(var(--brandRed) shade(10%));
}

@custom-selector :--button-green .btn--green, .custom-btn--green;
:--button-green {
	color: #fff;
	background: var(--brandGreen);
}
:--button-green:--enter {
	color: #fff;
	background: color(var(--brandGreen) shade(10%));
}

@custom-selector :--buttonMore .custom-btn--more;
:--buttonMore {
	background: none;
	border-bottom: 3px solid var(--brandRed);
  padding: 6px 14px;
  span {
    font-size: calc(var(--fontSize) * 1.125);
    font-weight: 500;
	  color: var(--brandRed);
    letter-spacing: 0;
  }
}
:--buttonMore:--enter {
  background: var(--brandRed);
  span {
    color: #fff;
  }
}

@custom-selector :--buttonMore2 .custom-btn--more2;
:--buttonMore2 {
	border-bottom: none;
	padding-left: 0;
  span {
    font-size: calc(var(--fontSize) * 1.125);
    font-weight: 700;
	  color: var(--brandRed);
    letter-spacing: 0;
		&:before {
			content: '';
			width: 100px;
			height: 3px;
			background: var(--brandRed);
			display: inline-block;
			vertical-align: middle;
			margin-right: 10px;
			transition: width .3s, color .3s;
		}
  }
}
:--buttonMore2:--enter {
	background: none;
	span {
    color: #000;
		&:before {
			width: 110px;
			transition: width .3s, color .3s;
			background: #000;
		}
  }
}