.m_nav--backdrop {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
  backface-visibility: hidden;
  background-color: color(#000 a(40%));
  visibility: hidden;
  opacity: 0;
  z-index: 99;
  &.open {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.4s 0s, visibility 0s 0s;
  }
}
.m_nav {
  position: fixed;
  top: 0;
  right: -30px;
  box-shadow: 0 15px 10px 0 color(#000 a(10%));
  width: 80%;
  height: 100%;
  background: #fff;
  transition: all ease 0.5s;
  z-index: 999;
  opacity: 1;
  visibility: visible;
  margin-top: 0;
  overflow-y: auto;
  transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1) 0ms;
  top: 0;
  transform: translateX(100%);
  &--toggler {
    position: absolute;
    top: 0;
    right: 0;
    transition: 0.5s ease-in-out;
    cursor: pointer;
    width: 80px;
    height: 100%;
    background: #eee;
    a {
      width: 100%;
      height: 100%;
      display: inline-block;
    }
    &__bars {
      position: absolute;
      width: 30px;
      height: 1px;
      top: 50%;
      left: 50%;
      transform: translate(-50%);
      background: #000;
      transform-origin: center;
      transition: 0.3s ease-in-out;
      &:before,
      &:after {
        content: '';
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        background: #000;
        transition: 0.3s ease-in-out;
      }
      &:before {
        top: -10px;
      }
      &:after {
        bottom: -10px;
      }
    }
  }
  &__top {
    cursor: pointer;
    &__closed {
      position: absolute;
      left: 30px;
      top: 20px;
      width: 32px;
      height: 32px;
      &__bars {
        display: inline-block;
        vertical-align: middle;
        width: 100%;
        height: 2px;
        background: var(--brandRed);
        position: relative;
        transition: all 0.2s ease-in-out;
        &:before,
        &:after {
          content: '';
          display: block;
          width: 100%;
          height: 2px;
          background: var(--brandRed);
          position: absolute;
          transition: all 0.2s ease-in-out;
        }
        &:before {
          top: 14px;
          animation: outbefore 0.5s;
          animation-fill-mode: forwards;
        }
        &:after {
          top: -14px;
          animation: outafter 0.5s;
          animation-fill-mode: forwards;
        }
      }
    }
  }
  &__logo {
    width: 100%;
    margin: 0;
    max-width: 300px;
    margin-top: 40px;
    img {
      display: block;
      margin: 10px auto;
    }
  }
  &__list {
    list-style: none;
    padding: 0;
    padding-top: 30px;
  }
  &__item {
    padding: 10px 0;
    transform: translateX(100%);
    visibility: hidden;
    transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1) 0ms;
    opacity: 0;
    &__content {
      display: block;
      text-decoration: none;
      color: #333;
      font-size: calc(var(--fontSize) * 1.125);
      height: 46px;
      line-height: 46px;
      width: 100%;
      font-weight: 600;
    }
  }
  &__item__dropdown {
    &__list {
      display: none;
      background: #eee;
      margin: 0 -20px;
      &__content {
        color: #333;
        display: block;
        height: 44px;
        line-height: 44px;
        padding: 0 30px;
        box-sizing: border-box;
        border-bottom: 1px solid #f5f5f5;
        &:last-child {
          border: none;
        }
      }
    }
    &.active {
      &__list {
        display: block;
      }
    }
  }
  .language-box {
    margin-top: 50px;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        display: inline-block;
        margin-right: 10px;
        &:last-child {
          margin-right: 0;
        }
        a {
          padding: 6px 0;
          font-weight: 600;
          color: #888;
          margin-right: 10px;
          &:--enter,
          &.current {
            color: var(--brandRed);
          }
        }
      }
    }
  }
  &--left {
    display: block;
    width: 100%;
    padding: 30px;
  }
  &--right {
    display: none;
  }
  &.open {
    transform: translateX(0);
    right: 0;
    .m_nav__list li {
      opacity: 1;
      transform: translateX(0);
      transition: opacity 0.4s ease-out, transform 0.4s ease-out;
      transition-delay: 0s, 0s;
      visibility: visible;
      &:nth-child(1) {
        transition-delay: 0.2s;
      }
      &:nth-child(2) {
        transition-delay: 0.25s;
      }
      &:nth-child(3) {
        transition-delay: 0.3s;
      }
      &:nth-child(4) {
        transition-delay: 0.35s;
      }
      &:nth-child(5) {
        transition-delay: 0.4s;
      }
      &:nth-child(6) {
        transition-delay: 0.45s;
      }
    }
    .m_nav__top {
      &__closed__bars {
        background: #fff;
        &:before {
          top: 0;
          animation: inbefore 0.5s;
          animation-fill-mode: forwards;
        }
        &:after {
          top: 0;
          animation: inafter 0.5s;
          animation-fill-mode: forwards;
        }
      }
    }
  }

  @media (--small-viewport) {
    .m_nav--toggler {
      width: 60px;
    }
  }
  @media (--large-viewport) {
    .m_nav__top {
      &__closed {
        left: 40px;
      }
    }
    .m_nav.open {
      width: 80%;
    }
    .m_nav--toggler {
      width: 110px;
    }
    .m_nav__inner {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      overflow: hidden;
    }
    .m_nav--left {
      flex-grow: 1;
      flex-basis: 50%;
      flex-shrink: 0;
      min-height: 100vh;
      padding: 40px;
    }
    .m_nav--right {
      display: flex;
      padding: 0;
      flex-shrink: 1;
      height: auto;
      flex-basis: 48%;
      background: #eee;
      .m_nav__cta {
        position: relative;
        overflow: hidden;
        width: 100%;

        transform: translateX(100%);
        visibility: hidden;
        transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1) 0ms;
        opacity: 0;

        a {
          width: 100%;
          min-height: 100vh;
          display: block;
        }
        &--image,
        &--image__hover {
          position: absolute;
          z-index: -1;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          margin: 0;
          transition: opacity cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.2s,
            transform cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.2s,
            -webkit-transform cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.2s;
          img {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            -o-object-fit: cover;
            object-fit: cover;
            -o-object-position: left 50%;
            object-position: left 50%;
          }
        }
        &--image__hover {
          z-index: -2;
          transition: transform cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.4s,
            -webkit-transform cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.4s;
          img {
            -o-object-position: left 50%;
            object-position: left 50%;
          }
        }
        &:--enter & {
          &--image {
            opacity: 0;
            transform: scale(1.1);
          }
          &--image__hover {
            transform: scale(1);
          }
        }
      }
    }
    .m_nav.open {
      .m_nav__cta {
        opacity: 1;
        transform: translateX(0);
        transition: opacity 0.4s ease-out, transform 0.4s ease-out;
        transition-delay: 0s, 0s;
        visibility: visible;
        transition-delay: 0.4s;
      }
    }
  }
  @media (--xlarge-viewport) {
    .m_nav.open {
      width: 70%;
    }
  }
  @media (--xxlarge-viewport) {
    .m_nav.open {
      width: 60%;
    }
  }
}
