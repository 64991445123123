img.mfp-img {
  padding: 0;
  max-width: 800px;
  max-height: 800px !important;
}
@media (--medium-viewport) {
  img.mfp-img {
    width: 100%;
  }
}

.mfp-bottom-bar {
  margin-top: 10px;
}
/* 
====== Zoom-out effect ======
*/
.mfp-zoom-out {
  /* start state */
  .mfp-with-anim {
    opacity: 0;
    transition: all 0.3s ease-in-out; 
    transform: scale(1.3); 
  }
  &.mfp-bg {
    opacity: 0;
	  transition: all 0.3s ease-out;
  }
  /* animate in */
  &.mfp-ready {
    .mfp-with-anim {
      opacity: 1;
      transform: scale(1); 
    }
    &.mfp-bg {
      opacity: 0.8;
    }
  }
  /* animate out */
  &.mfp-removing {
    .mfp-with-anim {
      transform: scale(1.3); 
      opacity: 0;
    }
    &.mfp-bg {
      opacity: 0;
    }
  }
}

/* 
====== Zoom effect ======
*/
.mfp-zoom-in {
  /* start state */
  .mfp-with-anim {
    opacity: 0;
    transition: all 0.2s ease-in-out; 
    transform: scale(0.8); 
  }
  
  &.mfp-bg {
    opacity: 0;
	  transition: all 0.3s ease-out;
  }
  /* animate in */
  &.mfp-ready {
    .mfp-with-anim {
      opacity: 1;
      transform: scale(1); 
    }
    &.mfp-bg {
      opacity: 0.8;
    }
  }
  /* animate out */
  &.mfp-removing {
    
    .mfp-with-anim {
      transform: scale(0.8); 
      opacity: 0;
    }
    &.mfp-bg {
      opacity: 0;
    }
  }
}

/* mfp close button */
.mfp-image-holder, 
.mfp-iframe-holder {
  /* touch-action: manipulation; */
  .mfp-close {
    top: -50px;
    color: #fff;
    font-size: 40px;
    width: 44px;
    text-align: center;
    padding-right: 0;
  }
}

/* title text */
.mfp-title {
  text-align: center;
  padding: 10px 20px;
  font-size: 18px;
  display: table;
  margin: 0 auto;
  margin-top: -30px;
  border-radius: 6px;
}

button {
  &.mfp-close, &.mfp-arrow {
    &:--enter {
      color: #fff;
      background: none;
    }
  }
}

