@keyframes slideIn {
  from {
    filter: blur(15px);
  }
  to {
    filter: blur(0);
  }
}

@keyframes slideOut {
  from {
    filter: blur(0);
  }
  to {
    filter: blur(15px);
  }
}

@keyframes inafter {
  0% { top: 14px; }
  70% { top: 0; transform: rotate(0deg); }
  100% { transform: rotate(45deg); }
}

@keyframes inbefore {
  0% { top: -14px; }
  70% { top: 0; transform: rotate(0deg); }
  100% { transform: rotate(-45deg); }
}

@keyframes outafter {
  0% { top: 0; transform: rotate(45deg); }
  70% { top: 0; transform: rotate(0deg); }
  100% { top: 14px; }
}

@keyframes outbefore { 
  0% { top: 0; transform: rotate(-45deg); }
  70% { top: 0; transform: rotate(0deg); }
  100% { top: -14px; }
}