.main-footer {
  position: relative;
  background: var(--footerColor);
  .footer-outer-box {
    padding: 30px 0;
  }
  .footer-inner-box {
    &--copyright {
      color: #bbb;
      font-size: calc(var(--fontSize) * 0.88);
    }
    &__footerLinks {
      &__title {
        margin-top: 7px;
        position: relative;
        color: #fff;
        font-size: calc(var(--fontSize) * 1);
      }
      &__list {
        list-style: none;
        padding: 0;
        margin-top: 25px;
        &__item {
          margin-bottom: 15px;
          color: #ddd;
          font-size: calc(var(--fontSize) * 0.88);
          span {
            display: inline-block;
            margin-right: 10px;
          }
          a {
            transition: all 0.5s ease;
            color: #ddd;
            &:--enter {
              color: var(--brandRed);
            }
          }
        }
      }
    }
  }
  .social-link {
    margin-top: 25px;
    &__item {
      display: inline-block;
      margin-right: 5px;
      &__content {
        color: #fff;
        background: var(--greyColor);
        width: 24px;
        height: 24px;
        line-height: 24px;
        padding: 0;
        text-align: center;
        &.facebook {
          background: var(--fbColor);
          &:--enter {
            color: #fff;
          }
        }
        &.twitter {
          background: var(--twColor);
          &:--enter {
            color: #fff;
          }
        }
        &.instagram {
          background: var(--igColor);
          &:--enter {
            color: #fff;
          }
        }
        &.line {
          background: var(--lineColor);
          &:--enter {
            color: #fff;
          }
        }
        &:--enter {
          transform: translateY(-3px);
        }
      }
    }
  }
}

@media (--medium-viewport) {
  .main-footer {
    .footer-inner-box {
      &__footerLinks {
        margin-bottom: 30px;
      }
    }
  }
}
