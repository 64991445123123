@charset "UTF-8";
/* Import Bootstrap core */
@import 'bootstrap/bootstrap.css';
@import 'bootstrap/bootstrap-xlgrid.css';

/* Plugins */
@import '../../node_modules/slick-carousel/slick/slick.css';
@import '../../node_modules/animate.css/animate.css';
@import '../../node_modules/magnific-popup/dist/magnific-popup.css';
/* @import '../../node_modules/swiper/dist/css/swiper.css'; */

/* Custom Plugins */
@import 'vendor/slick_custom.css';
@import 'vendor/magnific_popup_custom.css';
@import 'vendor/aos.css';
@import 'vendor/fontawesome.css';

/* Needed Variables */
@import 'variables/_variables.css';
@import 'variables/_buttons.css';
@import 'variables/_media.css';
@import 'variables/_keyframe.css';

@import '_base.css';
@import '_layout.css';
@import '_navigation.css';
@import '_navigation_mobile.css';
@import '_footer.css';
@import '_custom.css';
@import '_backtop.css';

/* Seperate Page Style */
@import 'page/slide.css';
@import 'page/home.css';
